import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import styles from "../assets/css/resorts.module.scss";
import MessageBox from "../components/Common/MessageBox";
import SearchResults from "../components/SearchResults";
import SearchResultsMobile from "../components/SearchResults/indexMobile.js";
import BoxPlaceImg from "../components/BoxPlaceImg";
import { connect } from "react-redux";
import { centrosGet, centrosFiltersSet } from "../redux/centros/actions";
import { appRedirect, appModal } from "../redux/app/actions";
import { useIntl, Link } from "gatsby-plugin-intl";
import l from "lodash";
import LocationSearchFull from "../components/Common/LocationSearchFull";
import Slider from "../components/Common/Slider";
import { StyledCustomSelect } from "../assets/styles/pageCommon";

const ResultadosPage = ({
	centrosGet,
	centrosFiltersSet,
	appRedirect,
	appModal,
	centros,
	centrosFilters,
	page,
	centrosLoading,
	bootLoaded,
	lugaresCentros,
	isMobile768,
}) => {
	const intl = useIntl();

	useEffect(() => {
		if (!centrosFilters.location.lat || !centrosFilters.location.long) {
			appRedirect({ to: "/" });
		}
	}, []);

	const handleMoreResults = () => {
		load();
	};

	const load = () => {
		centrosGet(intl.locale, {
			lat: centrosFilters.location.lat,
			long: centrosFilters.location.long,
			distancia: centrosFilters.distancia,
			sort: centrosFilters.sort,
			mode: "full",
			include_clima: 1,
			page,
			token: localStorage.getItem("token"),
		});
	};

	const sortOptions = [
		{
			label: "Nieve últimas horas",
			value: "nieve",
		},
		{
			label: "Nieve próximas horas",
			value: "nieveprox",
		},
		{
			label: "Más cercanos",
			value: "distancia",
		},
		{
			label: "Nombre",
			value: "title",
		},
		{
			label: "Favoritos",
			value: "favoritos",
		},
	];

	const [sortOption, setSortOption] = useState({});

	useEffect(() => {
		l.forEach(sortOptions, (option) => {
			if (option.value === centrosFilters.sort) {
				setSortOption(option);
			}
		});
	}, []);

	const handleSortChange = (option) => {
		setSortOption(option);
		centrosFiltersSet({
			sort: option.value,
		});
		centrosGet(intl.locale, {
			lat: centrosFilters.location.lat,
			long: centrosFilters.location.long,
			distancia: centrosFilters.distancia,
			sort: option.value,
			mode: "full",
			include_clima: 1,
			page: 1,
			token: localStorage.getItem("token"),
		});
	};

	return (
		<Layout>
			<Seo title="Resultados" />

			<section className={styles.results}>
				<article className={styles.bgResults}>
					<div className={styles.resultsWrapper}>
						<Link to="/" className="lineLink">
							<u>Volver a la home</u>
						</Link>
					</div>
				</article>
				<article className={styles.blackBg}>
					<div className={styles.resultsWrapper}>
						<div className={styles.mainContent}>
							<div className={styles.locationWrapper}>
								<LocationSearchFull />
							</div>
							{!isMobile768 && (
								<MessageBox
									text="¿Tienes dudas de cómo funciona Fresh Snow?"
									buttonText="Ayuda & FAQs"
									buttonUrl="/help"
								/>
							)}
							<div className={styles.orderBy}>
								<div className={styles.selectCol}>
									<p>
										<span>Ordenar por</span>
									</p>
									<div className={styles.selectWrapper}>
										<StyledCustomSelect
											classNamePrefix="custom-select"
											// components={{ IndicatorSeparator: () => null }}
											options={sortOptions}
											onChange={handleSortChange}
											value={sortOption}
											isSearchable={false}
										/>
									</div>
								</div>
								<p className={styles.resultsQty}>
									<span>{centros.length}</span> Resultados
								</p>
							</div>
							<SearchResults
								centros={centros}
								onMoreResults={handleMoreResults}
								loading={centrosLoading}
							/>
							<SearchResultsMobile
								centros={centros}
								onMoreResults={handleMoreResults}
								loading={centrosLoading}
							/>
						</div>
						<div className={styles.adsWrapper}>
							{bootLoaded &&
								lugaresCentros &&
								lugaresCentros.map((lugar, index) => {
									return (
										<BoxPlaceImg
											key={index}
											imgSrc={
												lugar.imagen_small && lugar.imagen_small.url
													? lugar.imagen_small.url
													: null
											}
											title={lugar.title}
											description={lugar.subtitle}
											url={lugar.name}
											link={`${lugar.centros.length} estaciones`}
										/>
									);
								})}
						</div>
					</div>
				</article>
				<article className={styles.whiteBg}>
					<div className={styles.presentWrapper}>
						<Slider
							layout="light"
							type="news"
							title="Actualidad"
							text="Mantente al corriente de las últimas noticias del sector traidas por nuestro partner Lugares de nieve."
						/>
					</div>
				</article>
			</section>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { isMobile768 } = state.app.global;
	return {
		centros: state.centros.data,
		centrosFilters: state.centros.filters,
		page: state.centros.page,
		centrosLoading: state.centros.loading,
		bootLoaded: state.app.boot.success,
		lugaresCentros:
			state.app.boot.data && state.app.boot.data.lugares_centros
				? state.app.boot.data.lugares_centros
				: null,
		isMobile768,
	};
};

const mapDispatchToProps = {
	centrosGet,
	centrosFiltersSet,
	appRedirect,
	appModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultadosPage);
